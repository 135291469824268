import { css, SerializedStyles } from '@emotion/react';

type MediaQueries = {
  xs: (css: SerializedStyles | string) => string
  sm: (css: SerializedStyles | string) => string
  md: (css: SerializedStyles | string) => string
  lg: (css: SerializedStyles | string) => string
  xl: (css: SerializedStyles | string) => string
  xxl: (css: SerializedStyles | string) => string
  hd: (css: SerializedStyles | string) => string
  hdex: (css: SerializedStyles | string) => string
  fk: (css: SerializedStyles | string) => string
}


export const breakpoints = {
  xs: 320,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  hd: 1920,
  hdex: 2440,
  fk: 3840
};

// Iterate through the sizes and create a media template
const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {}) as MediaQueries;

export default media;