import getConfig from "next/config";

const { publicRuntimeConfig = {} } = getConfig() || { publicRuntimeConfig: {} };

export const dev = Boolean(process.env.NODE_ENV !== "production");
export const stage = Boolean(
  process.env.NODE_ENV === "staging" ||
  process.env.NODE_ENV === "development" ||
  publicRuntimeConfig.API_ENV === "staging"
);

export const apiURL = stage ? "stage.be.brandung.de" : "typo3.brandung.de";
export const baseURL = stage
  ? "https://stage.fe.brandung.de"
  : "https://www.agentur-brandung.de";
