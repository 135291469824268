import React, { CSSProperties } from "react";
import styled from "@emotion/styled";
import { Global, css, keyframes } from "@emotion/react";
import emotionNormalize from "emotion-normalize";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import { between } from "polished";

interface StyledProps  {
  as?: React.ElementType
  css?: CSSProperties
}


const nprogressSpinner = keyframes`
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }
`;

const animation = css`
  animation: ${nprogressSpinner} 400ms linear infinite;
`;

const styledH1 = css`
  color: ${theme.colors.black};
  font-size: ${between("35px", "60px")};
  font-weight: 800;
  line-height: 1.15;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
  overflow-wrap: break-word;
  word-wrap: break-word;

  ${mq.xxl(`
    font-size: ${pxToRem(60)};
    line-height: 1.25;
    overflow-wrap: none;
  `)}
`;

export const H1 = styled("h1") <StyledProps>`
  ${styledH1};
`;

const styledH2 = css`
  color: ${theme.colors.black};
  font-size: ${between("32px", "50px")};
  font-weight: 800;
  line-height: 1.25;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;

  ${mq.xxl(`
    font-size: ${pxToRem(50)};
  `)}
`;

export const H2 = styled("h2") <StyledProps>`
  ${styledH2}
`;

const styledH3 = css`
  color: ${theme.colors.black};
  font-size: ${between("26px", "40px")};
  line-height: 1.25;
  font-weight: 800;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;

  ${mq.xxl(`
    font-size: ${pxToRem(40)};
  `)}
`;

export const H3 = styled("h3") <StyledProps>`
  ${styledH3}
`;

export const styledH4 = css`
  color: ${theme.colors.black};
  font-size: ${between("22px", "35px")};
  font-weight: 800;
  line-height: 1.4;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;

  ${mq.xxl(`
    font-size: ${pxToRem(35)};
    line-height: 1.2;
  `)}
`;

export const H4 = styled("h4") <StyledProps>`
  ${styledH4};
`;

type SubheadlineProps = {
  bold?: boolean
  uppercase?: boolean
  bolder?: boolean
}


export const Subheadline = styled.p`
  color: ${theme.colors.black};
  font-size: ${between("18px", "20px")};

  p {
    font-size: ${between("18px", "20px")};
  }

  margin-top: 0;

  ${({ uppercase }: SubheadlineProps) => uppercase && `text-transform: uppercase;`};
  ${({ bold }: SubheadlineProps) => bold && `font-weight: 600`};
  ${({ bolder }: SubheadlineProps) => bolder && `font-weight: 800`};

  ${mq.xxl(css`
    font-size: ${pxToRem(20)};

    p {
      font-size: ${pxToRem(20)};
    }
  `)}
`;

const styledP = css`
  font-size: ${between("16px", "18px")};
  line-height: 1.6;
  margin-top: 0;

  ul {
    padding-left: 20px;
  }

  ${mq.xxl(`
    font-size: ${pxToRem(16)};
  `)}
`;

export const P = styled("p") <StyledProps>`
  ${styledP};
`;

const styles = css`
  * {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    max-width: 100vw;
    min-width: 320px;
  }

  @media screen and (min-width: 1440px) {
    html {
      font-size: calc(16px + 24 * ((100vw - 1440px) / 4096));
    }
  }

  html,
  body {
    background-color: #fff;
    color: ${theme.colors.greyLight};
    display: flex;
    flex-direction: column;
    flex: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${theme.fonts.regular};
    width: 100%;
    height: 100%;
    line-height: 1.6;
    min-width: 320px;
  }

  img {
    height: auto;
    outline: 0;
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4 {
    color: ${theme.colors.black};
    font-weight: 800;
    margin-top: 0;
    text-align: center;
    text-transform: uppercase;
  }

  h1 {
    ${styledH1};
  }

  h2 {
    ${styledH2};
    margin-bottom:${pxToRem(24)}
  }

  h3 {
    ${styledH3};
  }

  h4 {
    ${styledH4};
  }

  p {
    ${styledP};
  }

  a {
    ${styledP};
    color: ${theme.colors.primary};
    text-decoration: underline;
  }

  b,
  strong {
    color: ${theme.colors.black};
  }

  .hidden {
    display: none;
  }

  .u-center {
    display: flex;
    align-items: center;
  }

 
  
 .u-list-marker--inside{
  list-style-position: inside
 }
 
  
 .u-list--icon {
  padding-left: 0!important;

  li {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 10px 10px 10px 40px;
    position: relative;

    &:before {
      content: ' ';
      background-repeat: no-repeat;
      background-size: contain;
      width: 27px;
      background-position: center center;
      height: 27px;
      left: 0;
      position: absolute;
      top: 10px;
    }
  }
 }

  .u-list--check {
    li:before {
      background-image: url(/static/svg/bulletpoint-check.svg);
    }
    
  }

  .u-list--customer-centricity {
    li:before {
      background-image: url('/static/svg/bulletpoint-customer-centricity.svg');
    }
  }

  .u-list--marketing {
    li:before {
      background-image: url('/static/svg/bulletpoint-marketing.svg');
    }
    
  }

  .u-list--quick-wins{
    li:before {
      background-image: url('/static/svg/bulletpoint-quick-wins.svg');
    }
    
  }

  .u-list--rocket{
    li:before {
      background-image: url('/static/svg/bulletpoint-rocket.svg');
    }
    
  }

  hr {
    border-top: 1px solid #d4d4d4;
  }

  .blur-up {
    filter: blur(10px);
    transition: filter 0.6s;
  }

  .blur-up.lazyloaded {
    filter: blur(0);
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .fullscreen {
    z-index: 99999;
  }

  .btn {
    border: none;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    padding: ${pxToRem(13)} ${pxToRem(25)};
    margin-top: 20px;
    text-transform: uppercase;
    align-self: center;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    width: 100%;

    ${mq.lg(css`
      width: auto;
    `)}
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    display: none;
  }
  /* NProgress */
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${theme.colors.primary};

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: ${`0 0 10px ${theme.colors.primary}, 0 0 5px ${theme.colors.primary}`};
    opacity: 1;

    transform: rotate(3deg) translate(0px, -4px);
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: none;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;

    ${mq.lg(css`
      display: block;
    `)}
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: ${theme.colors.primary};
    border-left-color: ${theme.colors.primary};
    border-radius: 50%;

    ${animation}
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .custom-select {
    &__control {
      border-radius: 0;
      border: 1px solid #d4d4d4;
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding: ${pxToRem(10)} ${pxToRem(15)};
    }

    &__single-value,
    &__placeholder {
      color: black;
      font-weight: 600;
      text-transform: uppercase;
    }

    &__placeholder {
      opacity: 0.7;
    }

    &__dropdown-indicator svg {
      fill: black;
      width: ${pxToRem(20)};
      height: ${pxToRem(20)};
    }

    &__menu {
      position: absolute;
      z-index: 999;
    }

    &__option--is-selected,
    &__option--is-focused {
      background: ${theme.colors.secondary};
      color: ${theme.colors.white};
    }
  }
`;

const storybookStyles = css``;

const GlobalStyles = ({ storybook }) => {
  return (
    <Global
      styles={css`
        ${emotionNormalize};
        ${styles};
        ${storybook && storybookStyles}
      `}
    />
  );
};

export default GlobalStyles;
